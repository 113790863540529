import React from "react";
import MultipleOfficesLP from "../MultipleOfficesLP";

function KinderJugendMedizinLP() {
  var officeArray = [
    {
      doctorName: "Dr. med Anne Bräutigam",
      doctorTitle: "FÄ Kinder und Jugendmedizin",
      officeLoc: "Finsterwalde",
      navLink: "/kinder-und-jugendmedizin-brautigam",
    },
    {
      doctorName: "Dipl.-Med. Maria-Theresia Schwab",
      doctorTitle: "FÄ Kinder und Jugendmedizin",
      officeLoc: "Herzberg (Elster)",
      navLink: "/kinder-und-jugendmedizin-schwab",
    },
  ];

  return (
    <MultipleOfficesLP
      officeType={"Kinder- und Jugendmedizin"}
      officeArray={officeArray}
    />
  );
}

export default KinderJugendMedizinLP;

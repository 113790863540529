import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import articleImg1 from "../assets/img/PraxisMendeKaupischEpikurLauchhammer.webp";

function News() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  const handleArticleExtend = (e) => {
    if (isMobile) {
      handleMobileArticle(e);
    } else {
      handleDesktopArticle(e);
    }
  };

  const handleDesktopArticle = (e) => {
    var _preview = e.target.parentNode.children[1].children[0].children[1];
    var _article1 = e.target.parentNode.children[1].children[0].children[2];
    var _article2 = e.target.parentNode.children[2];
    var extenderText = e.target;
    var _previewDataType = _preview.getAttribute("data-type");

    if (_previewDataType === null) {
      _preview.setAttribute("data-type", "hidden");
      _article1.removeAttribute("data-type");
      _article2.removeAttribute("data-type");
      extenderText.innerHTML = "Einklappen";
    } else if (_previewDataType === "hidden") {
      _article1.setAttribute("data-type", "hidden");
      _article2.setAttribute("data-type", "hidden");
      _preview.removeAttribute("data-type");
      extenderText.innerHTML = "Mehr erfahren";
    }
    return;
  };

  const handleMobileArticle = (e) => {
    var _preview = e.target.parentNode.children[3];
    var _article = e.target.parentNode.children[4];
    var extenderText = e.target;
    var _previewDataType = _preview.getAttribute("data-type");

    if (_previewDataType === null) {
      _preview.setAttribute("data-type", "hidden");
      _article.removeAttribute("data-type");
      extenderText.innerHTML = "Einklappen";
    } else if (_previewDataType === "hidden") {
      _article.setAttribute("data-type", "hidden");
      _preview.removeAttribute("data-type");
      extenderText.innerHTML = "Mehr erfahren";
    }
    return;
  };

  return (
    <section>
      <div className="news-wrapper padding-inline">
        <div className="news-header">Aktuelles</div>
        <div className="news-list">
          <div className="news-article">
            <div className="news-article-header">
              Gelungener Wechsel und Neustart
            </div>
            {isMobile ? (
              <>
                <img
                  className="news-article-img"
                  src={articleImg1}
                  alt="news-article-img"
                ></img>
                <div className="news-article-sub-header">
                  Rebecca Kaupisch übernimmt Epikur-Praxis von Dr. med. Marion
                  Mende
                </div>
                <div className="news-article-preview">
                  <p>
                    Lauchhammer. Länger als geplant, doch so lange, wie sie es
                    ihren Patienten zugesichert hatte, war Dr. med. Marion Mende
                    als Hausärztin in Lauchhammer tätig. Nun, sagt die
                    73-jährige Hausärztin, sei nach fast 48 Dienstjahren ein
                    guter Zeitpunkt gekommen, den weißen Kittel auszuziehen.
                    Einen perfekten Anlass für diesen Schritt bietet ihr Rebecca
                    Kaupisch (39), die ...
                  </p>
                </div>
                <div className="news-article-content" data-type="hidden">
                  <p>
                    Lauchhammer. Länger als geplant, doch so lange, wie sie es
                    ihren Patienten zugesichert hatte, war Dr. med. Marion Mende
                    als Hausärztin in Lauchhammer tätig. Nun, sagt die
                    73-jährige Hausärztin, sei nach fast 48 Dienstjahren ein
                    guter Zeitpunkt gekommen, den weißen Kittel auszuziehen.
                    Einen perfekten Anlass für diesen Schritt bietet ihr Rebecca
                    Kaupisch (39), die mit Beginn des Jahres 2024 die von Dr.
                    Mende geführte Praxis der MVZ Epikur GmbH samt der vier
                    Praxisschwestern übernimmt. Auch die Kassenärztliche
                    Vereinigung hat dem Wechsel bereits zugestimmt.
                  </p>
                  <br />
                  <p>
                    Die Bekanntschaft beider Frauen währt bereits acht Jahre.
                    Schon damals, 2015, war es die Schrader-Beratung aus
                    Finsterwalde, die den Kontakt vermittelte. Und auch jetzt,
                    da Rebecca Kaupisch nach dem Studium in Greifswald sowie
                    medizinischen Lehrjahren in Würzburg und Senftenberg ihre
                    Facharztprüfung zur Internistin erfolgreich bestand und ihr
                    Können am Klinikum Finsterwalde bewies, war es Uwe Schrader,
                    der die erfolgreiche Praxisnachfolge auf den Weg brachte.
                  </p>
                  <br />
                  <p>
                    In den vergangenen fünf Jahren gehörte Rebecca Kaupisch dem
                    Team des Elbe-Elster-Klinikum an. Dessen aktuelle
                    Entwicklung, sagt sie, habe sie aber bestärkt, in die
                    ambulante Patientenversorgung zu wechseln. “Mir ist es
                    wichtig, zu meinen Patienten einen engen und längjährigen
                    Kontakt zu pflegen, was im Klinikbetrieb kaum möglich ist.
                    Darüber hinaus habe ich eine Zusatzqualifikation im Bereich
                    der Diabetologie absolviert. Ein Bereich, der mir sehr am
                    Herzen liegt. Die Versorgung der betroffenen Patienten
                    findet fast ausschließlich in den Ambulanzen statt”, betont
                    die junge Frau, die mit ihrer Familie in Doberlug-Kirchhain
                    lebt. Als einen Glücksfall erachtet es Kaupisch, dass auch
                    Dr. Mende als Diabetologin arbeitete, wodurch die modernen
                    Praxisräume in der Bockwitzer Straße 91 für diese Arbeit
                    beste Strukturen bereit hielten.
                  </p>
                  <br />
                  <p>
                    Dr. Marion Mende wird sich am 21. Dezember 2023 letztmalig
                    ihrer Patienten annehmen. “Ich räume meinen Platz aber ohne
                    Wehmut und freue mich auf den Lebensabschnitt, der nun vor
                    mir liegt”, betont sie. Ihre Patienten habe sie bereits
                    frühzeitig darüber informiert, dass deren medizinische
                    Versorgung langfristig sichergestellt sei. “Zu Frau Kaupisch
                    und ihren Befähigungen als Ärztin habe ich vollstes
                    Vertrauen und wünsche mir daher, dass meine Patienten sich
                    auch ihr anvertrauen und die Treue halten”, fügt sie an.
                  </p>
                  <div className="news-article-author">Author: Sven Gückel</div>
                </div>
                <div
                  className="btn-article-extender"
                  onClick={(e) => handleArticleExtend(e)}
                >
                  Mehr erfahren
                </div>
              </>
            ) : (
              <>
                <div className="news-article-divider">
                  <div className="news-article-content-wrapper">
                    <div className="news-article-sub-header">
                      Rebecca Kaupisch übernimmt Epikur-Praxis von Dr. med.
                      Marion Mende
                    </div>
                    <div className="news-article-preview">
                      <p>
                        Lauchhammer. Länger als geplant, doch so lange, wie sie
                        es ihren Patienten zugesichert hatte, war Dr. med.
                        Marion Mende als Hausärztin in Lauchhammer tätig. Nun,
                        sagt die 73-jährige Hausärztin, sei nach fast 48
                        Dienstjahren ein guter Zeitpunkt gekommen, den weißen
                        Kittel auszuziehen. Einen perfekten Anlass für diesen
                        Schritt bietet ihr Rebecca Kaupisch (39), die mit Beginn
                        des Jahres 2024 die von Dr. Mende geführte Praxis der
                        MVZ Epikur GmbH samt der vier Praxisschwestern
                        übernimmt. Auch die Kassenärztliche Vereinigung hat dem
                        Wechsel bereits zugestimmt.
                      </p>
                      <br />
                      <p>
                        Die Bekanntschaft beider Frauen währt bereits acht
                        Jahre. Schon damals, 2015, war es die Schrader-Beratung
                        aus Finsterwalde, die den Kontakt vermittelte. Und auch
                        jetzt, da Rebecca Kaupisch nach dem Studium in
                        Greifswald sowie medizinischen Lehrjahren in Würzburg
                        und Senftenberg ihre Facharztprüfung zur Internistin
                        erfolgreich bestand und ihr Können am Klinikum
                        Finsterwalde bewies, war es Uwe Schrader, der ...
                      </p>
                    </div>
                    <div className="news-article-content" data-type="hidden">
                      <p>
                        Lauchhammer. Länger als geplant, doch so lange, wie sie
                        es ihren Patienten zugesichert hatte, war Dr. med.
                        Marion Mende als Hausärztin in Lauchhammer tätig. Nun,
                        sagt die 73-jährige Hausärztin, sei nach fast 48
                        Dienstjahren ein guter Zeitpunkt gekommen, den weißen
                        Kittel auszuziehen. Einen perfekten Anlass für diesen
                        Schritt bietet ihr Rebecca Kaupisch (39), die mit Beginn
                        des Jahres 2024 die von Dr. Mende geführte Praxis der
                        MVZ Epikur GmbH samt der vier Praxisschwestern
                        übernimmt. Auch die Kassenärztliche Vereinigung hat dem
                        Wechsel bereits zugestimmt.
                      </p>
                      <br />
                      <p>
                        Die Bekanntschaft beider Frauen währt bereits acht
                        Jahre. Schon damals, 2015, war es die Schrader-Beratung
                        aus Finsterwalde, die den Kontakt vermittelte. Und auch
                        jetzt, da Rebecca Kaupisch nach dem Studium in
                        Greifswald sowie medizinischen Lehrjahren in Würzburg
                        und Senftenberg ihre Facharztprüfung zur Internistin
                        erfolgreich bestand und ihr Können am Klinikum
                        Finsterwalde bewies, war es Uwe Schrader, der die
                        erfolgreiche Praxisnachfolge auf den Weg brachte.
                      </p>
                    </div>
                  </div>
                  <img
                    className="news-article-img"
                    src={articleImg1}
                    alt="news-article-img"
                  ></img>
                </div>
                <div className="news-article-content" data-type="hidden">
                  <p>
                    In den vergangenen fünf Jahren gehörte Rebecca Kaupisch dem
                    Team des Elbe-Elster-Klinikum an. Dessen aktuelle
                    Entwicklung, sagt sie, habe sie aber bestärkt, in die
                    ambulante Patientenversorgung zu wechseln. “Mir ist es
                    wichtig, zu meinen Patienten einen engen und längjährigen
                    Kontakt zu pflegen, was im Klinikbetrieb kaum möglich ist.
                    Darüber hinaus habe ich eine Zusatzqualifikation im Bereich
                    der Diabetologie absolviert. Ein Bereich, der mir sehr am
                    Herzen liegt. Die Versorgung der betroffenen Patienten
                    findet fast ausschließlich in den Ambulanzen statt”, betont
                    die junge Frau, die mit ihrer Familie in Doberlug-Kirchhain
                    lebt. Als einen Glücksfall erachtet es Kaupisch, dass auch
                    Dr. Mende als Diabetologin arbeitete, wodurch die modernen
                    Praxisräume in der Bockwitzer Straße 91 für diese Arbeit
                    beste Strukturen bereit hielten.
                  </p>
                  <br />
                  <p>
                    Dr. Marion Mende wird sich am 21. Dezember 2023 letztmalig
                    ihrer Patienten annehmen. “Ich räume meinen Platz aber ohne
                    Wehmut und freue mich auf den Lebensabschnitt, der nun vor
                    mir liegt”, betont sie. Ihre Patienten habe sie bereits
                    frühzeitig darüber informiert, dass deren medizinische
                    Versorgung langfristig sichergestellt sei. “Zu Frau Kaupisch
                    und ihren Befähigungen als Ärztin habe ich vollstes
                    Vertrauen und wünsche mir daher, dass meine Patienten sich
                    auch ihr anvertrauen und die Treue halten”, fügt sie an.
                  </p>
                  <div className="news-article-author">Author: Sven Gückel</div>
                </div>
                <div
                  className="btn-article-extender"
                  onClick={(e) => handleArticleExtend(e)}
                >
                  Mehr erfahren
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;

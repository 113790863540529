import React from "react";
import MultipleOfficesLP from "../MultipleOfficesLP";

function RheumatologieLP() {
  var officeArray = [
    {
      doctorName: "Dr. med. Joachim-Michael Engel",
      doctorTitle: "FA für Innere Medizin - Rheumatologie",
      officeLoc: "Bad Liebenwerda",
      navLink: "/rheumatologie-engel",
    },
    {
      doctorName: "Sebastian Patzschke",
      doctorTitle: "FA für Innere Medizin - Rheumatologie",
      officeLoc: "Großthiemig",
      navLink: "/rheumatologie-patzschke",
    },
  ];

  return (
    <MultipleOfficesLP officeType={"Rheumatologie"} officeArray={officeArray} />
  );
}

export default RheumatologieLP;

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./assets/styles/App.css";
import Main from "./pages/Main";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Jobs from "./pages/Jobs";
import News from "./pages/News";
import PraxisFinder from "./components/sites/PraxisFinder";
import Urologie from "./components/sites/Urologie";
import FrauenheilkundeGeburtshilfe from "./components/sites/FrauenheilkundeGeburtshilfe";
import HalsNasenOhrenHeilkunde from "./components/sites/HalsNasenOhrenheilkunde";
import OrthopaedieUnfallchirurgie from "./components/sites/OrthopaedieUnfallchirurgie";
import RheumatologieLP from "./components/sites/Rheumatologie/RheumatologieLP";
import RheumatologieEngel from "./components/sites/Rheumatologie/RheumatologieEngel";
import RheumatologiePatschke from "./components/sites/Rheumatologie/RheumatologiePatschke";
import KinderJugendMedizinLP from "./components/sites/KinderJugendMedizin/KinderJugendMedizinLP";
import KinderJugendMedizinBrautigam from "./components/sites/KinderJugendMedizin/KinderJugendMedizinBrautigam";
import KinderJugendMedizinSchwab from "./components/sites/KinderJugendMedizin/KinderJugendMedizinSchwab";
import InnereMedizinDiabetologieLP from "./components/sites/InnereMedizinDiabetologie/InnereMedizinDiabetologieLP";
import InnereMedizinDiabetologieKaupisch from "./components/sites/InnereMedizinDiabetologie/InnereMedizinDiabetologieKaupisch";
import InnereMedizinDiabetologieEbenroth from "./components/sites/InnereMedizinDiabetologie/InnereMedizinDiabetologieEbenroth";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="/praxis-finder" element={<PraxisFinder />} />
            <Route path="/aktuelles" element={<News />} />
            <Route path="/rheumatologie" element={<RheumatologieLP />} />
            <Route
              path="/rheumatologie-engel"
              element={<RheumatologieEngel />}
            />
            <Route
              path="/rheumatologie-patzschke"
              element={<RheumatologiePatschke />}
            />

            <Route
              path="/kinder-und-jugendmedizin"
              element={<KinderJugendMedizinLP />}
            />
            <Route
              path="/kinder-und-jugendmedizin-brautigam"
              element={<KinderJugendMedizinBrautigam />}
            />
            <Route
              path="/kinder-und-jugendmedizin-schwab"
              element={<KinderJugendMedizinSchwab />}
            />

            <Route path="/urologie" element={<Urologie />} />
            <Route
              path="/frauenheilkunde-geburtshilfe"
              element={<FrauenheilkundeGeburtshilfe />}
            />
            <Route
              path="/innere-medizin-diabetologie"
              element={<InnereMedizinDiabetologieLP />}
            />
            <Route
              path="/innere-medizin-diabetologie-ebenroth"
              element={<InnereMedizinDiabetologieEbenroth />}
            />
            <Route
              path="/innere-medizin-diabetologie-kaupisch"
              element={<InnereMedizinDiabetologieKaupisch />}
            />
            <Route
              path="/hals-nasen-ohrenheilkunde"
              element={<HalsNasenOhrenHeilkunde />}
            />
            <Route
              path="/orthopaedie-unfallchirurgie"
              element={<OrthopaedieUnfallchirurgie />}
            />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import PraxisTemplate from "../template/PraxisTemplate";
import praxisInfoList from "../../data/praxisInfoList.json";

function FrauenheilkundeGeburtshilfe() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="praxis-container">
      <PraxisTemplate
        praxisInfos={praxisInfoList[0]["Frauenheilkunde und Geburtshilfe"]}
      />
    </div>
  );
}

export default FrauenheilkundeGeburtshilfe;

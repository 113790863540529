import React, { useState, useEffect } from "react";
import MobileSliderWithInfoPanel from "../ImgGallery/ImgSliderWithInfoPanel/MobileSliderWithInfoPanel";
import DesktopSliderWithInfoPanel from "../ImgGallery/ImgSliderWithInfoPanel/DesktopSliderWithInfoPanel";
import { useMediaQuery } from "react-responsive";
import filterData from "../../data/filterList.json";
import praxisInfosData from "../../data/praxisInfoList.json";
import phoneIcon from "../../assets/img/contact_phone_icon.webp";
import mailIcon from "../../assets/img/mail_icon.webp";
import { HashLink } from "react-router-hash-link";

function PraxisFilter() {
  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    getAllOffices();
  }, []);

  const sortSingleElementsAndArrayElements = (elementList) => {
    var _result = [];
    if (elementList.length > 1) {
      elementList.forEach((item) => {
        _result.push(item);
      });
    } else {
      _result.push(elementList);
    }
    return _result;
  };

  const getAllOffices = () => {
    var _resultList = [];
    filterData.forEach((item) => {
      var _temp = sortSingleElementsAndArrayElements(
        praxisInfosData[0][item["Value"]]
      );
      if (_temp.length > 1) {
        _temp.forEach((element) => _resultList.push(element));
      } else {
        _resultList.push(_temp[0]);
      }
    });
    setResultList(_resultList);
  };

  const handleChange = (event) => {
    const filterResultArray = praxisInfosData[0][event.target.value];
    if (filterResultArray) {
      setResultList(sortSingleElementsAndArrayElements(filterResultArray));
    } else {
      getAllOffices();
    }
  };

  return (
    <>
      <div className="pf-filter-container">
        <div className="pf-filter-header-wrapper">
          <div className="pf-filter-header">
            Medizinisches Versorgungszentrum
          </div>
          <div className="pf-filter-sub-header">
            Unsere Arztpraxen in Südbrandenburg
          </div>
        </div>
        <div className="pf-filter-wrapper">
          <div className="pf-filter-item-wrapper">
            <select
              className="pf-filter-item"
              id="office"
              name="office"
              onChange={(e) => handleChange(e)}
            >
              <option value="none" selected>
                Facharzt für ...
              </option>
              {filterData.map((element) => {
                return (
                  <option className="pf-filter-option" value={element["Value"]}>
                    {element["Key"]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="pf-search-result-wrapper">
        <div className="pf-search-result-text">
          {resultList.length + " Ergebnisse Ihrer Facharztsuche"}
        </div>
        <div className="pf-search-result-list">
          {resultList.map((result) => {
            return (
              <div
                className="pf-search-result-list-item"
                key={
                  result["fakultat"] + "_" + result["doctor"].replace(/ /g, "")
                }
              >
                <div className="pf-search-result-list-item-info">
                  <div className="pf-search-result-list-item-heading">
                    <div className="pf-search-result-list-item-header">
                      {result["fakultat"]}
                    </div>
                    <div className="pf-search-result-list-item-subheader">
                      in {result["city"]}
                    </div>
                  </div>
                  <div className="pf-search-result-list-item-location">
                    <div className="pf-search-result-list-item-location-street">
                      {result["street"]}
                    </div>
                    <div className="pf-search-result-list-item-location-city">
                      {result["postalCode"] + " " + result["city"]}
                    </div>
                  </div>
                  <div className="pf-search-result-list-item-info">
                    {result["phone"] ? (
                      <div className="pf-search-result-list-item-info-item">
                        <img
                          className="pf-search-result-list-item-info-item-icon"
                          src={phoneIcon}
                          alt="phone_icon"
                        ></img>
                        <div className="pf-search-result-list-item-info-item-text">
                          {result["phone"]}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="pf-search-result-list-item-control">
                  <HashLink
                    to={result["maps-link"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn"
                    data-type="green-border"
                  >
                    Anfahrt planen
                  </HashLink>
                  <HashLink
                    to={result["link"]}
                    className="btn"
                    data-type="orange-border"
                  >
                    Zur Fachpraxis
                  </HashLink>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function PraxisFinder() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  return (
    <div className={isMobile ? "" : "padding-inline"}>
      <div className="pf-wrapper">
        <PraxisFilter />
      </div>
    </div>
  );
}

export default PraxisFinder;

import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

function MultipleOfficesLP({ officeType, officeArray }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <section id="praxen">
      <div className="padding-block-700 padding-inline text-center">
        <h1 className="praxen-header">{officeType}</h1>
        <div className="praxis-card-list">
          {officeArray.map((element) => {
            return (
              <div className="praxis-card">
                <div className="praxis-card-header">{element.doctorName}</div>
                <div className="praxis-card-subheader">
                  {element.doctorTitle} <br />
                  {element.officeLoc}
                </div>
                <HashLink className="btn" to={element.navLink}>
                  Zur Praxis
                </HashLink>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default MultipleOfficesLP;

import React, { useEffect } from "react";
import PraxisTemplateDetailed from "../template/PraxisTemplateDetailed";
import praxisInfoList from "../../data/praxisInfoList.json";

function HalsNasenOhrenheilkunde() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="praxis-container">
      <PraxisTemplateDetailed
        praxisInfos={praxisInfoList[0]["Hals-Nasen-Ohrheilkunde"]}
      />
    </div>
  );
}

export default HalsNasenOhrenheilkunde;

import React, { useState } from "react";
import chevronDown from "../../assets/img/chevron_icon_down_white.webp";
import chevronUp from "../../assets/img/chevron_icon_up_white.webp";

function ExtendedServiceCard({ serviceTitle, serviceDescription }) {
  const [cardClosed, setCardClosed] = useState(true);

  const handleClick = () => {
    setCardClosed(!cardClosed);
  };

  return (
    <div className="praxis-extended-service-card" onClick={handleClick}>
      <div className="praxis-extended-service-card-header-wrapper">
        <div className="praxis-extended-service-card-header">
          {serviceTitle}
        </div>
        <img
          className="praxis-extended-service-card-control"
          src={cardClosed ? chevronDown : chevronUp}
          alt="cardChevron"
        ></img>
      </div>
      <div
        className="praxis-extended-service-card-dropdown"
        data-type={cardClosed ? "" : "open"}
      >
        {serviceDescription.map((descText, j) => {
          return (
            <div className="praxis-element-service-list-item">
              <div className="praxis-element-service-list-divider"></div>
              <div
                className="praxis-element-service-list-item-text"
                key={"item_" + j}
              >
                {descText}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ExtendedServiceCard;

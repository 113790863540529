import React from "react";
import MultipleOfficesLP from "../MultipleOfficesLP";

function InnereMedizinDiabetologieLP() {
  var officeArray = [
    {
      doctorName: "Dipl. Med. Ines Ebenroth",
      doctorTitle:
        "FÄ für Innere Medizin / Diabetologin DDG Hausärztlich tätige Internistin / SPP Diabetologin",
      officeLoc: "Bad Liebenwerda",
      navLink: "/innere-medizin-diabetologie-ebenroth",
    },
    {
      doctorName: "Rebecca Kaupisch und Dr. med. Marion Mende",
      doctorTitle: "FÄ für Innere Medizin / SPP Diabetologin",
      officeLoc: "Lauchhammer",
      navLink: "/innere-medizin-diabetologie-kaupisch",
    },
  ];

  return (
    <MultipleOfficesLP
      officeType={"Innere Medizin SP Diabetologie"}
      officeArray={officeArray}
    />
  );
}

export default InnereMedizinDiabetologieLP;

import React from "react";
import { HashLink } from "react-router-hash-link";

function Footer() {
  const YEAR = new Date().getFullYear();

  return (
    <footer>
      <div className="primary-footer-wrapper">
        <div className="primary-footer-nav">
          <HashLink to="/jobs">Jobs</HashLink>
          <HashLink to="/impressum">Impressum</HashLink>
          <HashLink to="/datenschutz">Datenschutz</HashLink>
        </div>
        <div className="primary-footer-links">
          <p>{"© " + YEAR + " MVZ Epikur GmbH"}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

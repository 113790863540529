import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";
import praxisInfoList from "../../../data/praxisInfoList.json";

function RheumatologiePatschke() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="praxis-container">
      <PraxisTemplate praxisInfos={praxisInfoList[0]["Rheumatologie"][1]} />
    </div>
  );
}

export default RheumatologiePatschke;

import React from "react";
import MobileImgGallery from "./ImgGallery/MobileImgGallery";
import DesktopImgGallery from "./ImgGallery/DesktopImgGallery";

function ImageGallery({ imgList, mobile }) {
  return (
    <>
      {mobile ? (
        <>{<MobileImgGallery Items={imgList} maxItems={imgList.length} />}</>
      ) : (
        <>{<DesktopImgGallery Items={imgList} maxItems={imgList.length} />}</>
      )}
    </>
  );
}

export default ImageGallery;

import React, { useEffect, useState, useCallback, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import chevronRight from "../../../assets/img/chevron_right_orange.webp";
import chevronLeft from "../../../assets/img/chevron_left_orange.webp";

function MobileSliderWithInfoPanel({
  Items,
  maxItems,
  infoTitle,
  infoHeader,
  infoText,
  showBtn,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoplayRef = useRef();

  const settings = {
    maxItems: maxItems,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goTo = useCallback(
    (index) => {
      if (!isAnimating) {
        setCurrentIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settings.speed);
      }
    },
    [isAnimating, currentIndex]
  );

  /* Go Next */
  const goNext = () => {
    goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
  };

  /* Go Prev */
  const goPrev = () => {
    goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  useEffect(() => {
    if (settings.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);
      autoplayRef.current = setTimeout(() => {
        goNext();
      }, settings.autoplaySpeed);
    }
  }, [currentIndex, activeAutoplay, isAnimating]);

  const dots = () => {
    const totalItems = Array.apply(null, Array(settings.maxItems));
    const dotsButtons = totalItems.map((item, index) => {
      return (
        <div
          className={
            index === currentIndex
              ? "swip-slider-pagination-item swip-slider-pagination-item-active"
              : "swip-slider-pagination-item"
          }
          key={index.toString()}
          onMouseEnter={pauseTimer}
          onMouseLeave={playTimer}
          onClick={() => goTo(index)}
        ></div>
      );
    });
    return <div className="swip-slider-pagination">{dotsButtons}</div>;
  };

  const control = () => {
    return (
      <div className="swip-slider-pagination-control">
        <div className="swip-slider-pagination-chevron">
          <img src={chevronLeft} alt="chevron-left" onClick={goPrev}></img>
        </div>
        <div></div>
        <div className="swip-slider-pagination-chevron">
          <img src={chevronRight} alt="chevron-right" onClick={goNext}></img>
        </div>
      </div>
    );
  };

  const slide = (index) => {
    let currentImg = require("../../../assets/img/" + Items[index]);

    return (
      <img
        src={currentImg}
        className="swip-slider-img"
        alt="swip-slider-img"
      ></img>
    );
  };

  return (
    <div className="swip-wrapper">
      <div className="swip-slider">
        {control()}
        {dots()}
        {slide(currentIndex)}
      </div>
      <div className="swip-info">
        <div className="swip-info-bookmark">
          <div className="swip-info-bookmark-title">{infoTitle}</div>
        </div>
        <div className="swip-info-wrapper">
          <div className="swip-info-wrapper-title">{infoHeader}</div>
          <p className="swip-info-wrapper-text">{infoText}</p>
          {showBtn ? (
            <HashLink
              to={"/praxis-finder"}
              className="btn"
              data-type="orange-border"
            >
              Praxis finden
            </HashLink>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileSliderWithInfoPanel;
